<template>
  <!-- 之前的 -->
  <el-container>
    <!-- 顶部 -->
    <el-header>
      <Header />
    </el-header>
    <el-container>
      <!-- 左侧边栏 -->
      <el-aside width="220px">
        <div :class="isCollapse ? 'collapse-btn2' : 'collapse-btn'" @click="collapseChage">
          <i v-if="!isCollapse" class="el-icon-s-fold"></i>
          <i v-else class="el-icon-s-unfold"></i>
        </div>
        <el-menu :default-active="activePath" class="el-menu-vertical-demo" :collapse="isCollapse" background-color="#e2e2e2" text-color="#000" active-text-color="#1B63C1" @select="handleSelect" unique-opened router>

          <!-- 科室管理员 -->
          <el-submenu index="8" v-show="one">
            <template slot="title">
              <i class="iconfont icon-jixiaoguanli"></i>
              <span slot="title">机构信息管理</span>
            </template>
            <el-menu-item index="/oridepman" @click="saveNavState('/oridepman')">科室管理</el-menu-item>
            <el-menu-item index="/oridisman" @click="saveNavState('/oridisman')">学科管理</el-menu-item>
            <el-menu-item index="/oridebaman" @click="saveNavState('/oridebaman')">科室基础管理</el-menu-item>
            <!-- <el-menu-item index="/oriporeman" @click="saveNavState('/oriporeman')">岗位职责管理</el-menu-item> -->
            <el-menu-item index="/oripeinman" @click="saveNavState('/oripeinman')">人员信息管理</el-menu-item>
          </el-submenu>

          <el-submenu index="10">
            <template slot="title">
              <i class="iconfont icon-zhibiaokuchaxun"></i>
              <span slot="title">绩效指标库管理</span>
            </template>
            <el-menu-item index="/basindic" @click="saveNavState('/basindic')">基本指标</el-menu-item>
            <el-menu-item index="/workindic" @click="saveNavState('/workindic')">工作量指标</el-menu-item>
            <el-menu-item index="/spereindic" @click="saveNavState('/spereindic')">专项奖励指标</el-menu-item>
            <el-menu-item index="/indsubindic" @click="saveNavState('/indsubindic')">单项补助及奖惩指标</el-menu-item>
            <el-menu-item index="/indattindic" @click="saveNavState('/indattindic')">个人考勤指标</el-menu-item>
            <el-menu-item index="/addresindic" @click="saveNavState('/addresindic')">附加职责</el-menu-item>
          </el-submenu>

          <el-submenu index="11">
            <template slot="title">
              <i class="iconfont icon-yonghuguanli"></i>
              <span slot="title">科室绩效分配方案</span>
            </template>
            <el-menu-item index="/depperindicman" @click="saveNavState('/depperindicman')">科室绩效应用方案</el-menu-item>
            <el-menu-item index="/depperallsch" @click="saveNavState('/depperallsch')">科室绩效分配方案</el-menu-item>
          </el-submenu>

          <el-submenu index="12">
            <template slot="title">
              <i class="iconfont icon-yonghuguanli"></i>
              <span slot="title">科室绩效数据填报</span>
            </template>
            <el-menu-item index="/depperbusdafi" @click="saveNavState('/depperbusdafi')">科室绩效数据填报</el-menu-item>
          </el-submenu>

          <el-submenu index="9" v-show="one">
            <template slot="title">
              <i class="iconfont icon-yonghuguanli1-copy"></i>
              <span slot="title">绩效薪酬核算管理</span>
            </template>
            <el-menu-item index="/copeplan" @click="saveNavState('/copeplan')">绩效核算</el-menu-item>
          </el-submenu>


          <el-submenu index="6" v-show="six">
            <template slot="title">
              <i class="iconfont icon-jiegou1"></i>
              <span slot="title">驱动型绩效智慧大数据</span>
            </template>
            <el-menu-item index="/driven" @click="saveNavState('/driven')">驱动型绩效智慧大数据</el-menu-item>
          </el-submenu>
        </el-menu>

      </el-aside>
      <!-- 中间内容 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from "@/components/Header";
export default {
  data() {
    return {
      // 存储左菜单栏的路径
      activePath: "",
      // 左侧边栏
      isCollapse: false,
      one: true, //医院基础数据
      two: true, //科室基础数据
      three: true, //科室标化工作量
      four: true, //科室难度系数监测
      five: true, //绩效测算与模拟
      six: true, //驱动型绩效大智慧
    };
  },
  components: {
    Header,
  },
  // 通过监听器解决  导航菜单在页面刷新时会回到默认项
  watch: {
    $route(to) {
      // 监听路由的变化  如果路由发生改变则当前tab栏默认值也相应改变
      this.activePath = to.path;
    },
  },
  created() {
    // 当前点击进来的左菜单栏显示
    // console.log(this.$route.meta.activeMenu)
    this.activePath = this.$route.meta.activeMenu;
  },
  methods: {
    // 左菜单栏点击跳转的页面路径
    handleSelect(key) {
      // console.log(key)
      this.$router.push(key);
    },
    // 侧边栏折叠
    collapseChage() {
      this.isCollapse = !this.isCollapse;
    },
    // 存储点击左菜单栏的路径
    saveNavState(activePath) {
      // console.log(activePath)
      window.sessionStorage.setItem("activePath", activePath);
      this.activePath = activePath;
    },
  },
};
</script>
<style lang="less" scoped>
.el-container.is-vertical {
  height: 100%;
}
.el-menu--collapse {
  height: 100%;
}
.el-menu-vertical-demo {
  text-align: center;
  .iconfont {
    margin-right: 8px;
  }
}
/deep/ .el-submenu__icon-arrow {
  position: absolute;
  top: 54%;
  right: 10px;
  margin-top: -7px;
  transition: transform 0.3s;
  font-size: 12px;
}

.collapse-btn,
.collapse-btn2 {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  background: #f1f1f1;
  text-align: center;
}
.collapse-btn2 {
  width: 64px;
}
/deep/ .el-radio-button__inner {
  width: 100px;
}

.el-aside {
  overflow: hidden;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 220px;
}

/deep/ .el-header {
  position: relative;
  width: 100%;
  height: 80px !important;
  display: flex;
  align-items: center;
  background: #1b63c1;
  color: white;
  justify-content: space-between;
}

/deep/ .el-submenu__title,
/deep/ .el-menu-item {
  font-size: 13px;
}

/deep/ .el-menu-item {
  background: #ccc !important;
}

// 鼠标移入父级的颜色
/deep/ .el-submenu__title:hover {
  background-color: transparent !important;
}

/deep/ .el-menu,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  height: 100%;
}
</style>
